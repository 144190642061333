(function () {
  'use strict';

  angular
    .module('events.list')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('settings.events.list', {
        url: '',
        templateUrl: 'settings/events/list/list.tpl.html',
        controller: 'EventsListCtrl',
        controllerAs: 'vm'
      })
    ;
  }
}());
